import {
  initialState,
  SET_CURRENT_STUDENT,
  SAVE_SDQ_RESULT,
  SAVE_SNAP_IV_RESULT,
  SAVE_EQ_RESULT,
  SAVE_S9_RESULT,
  SAVE_WHO5_RESULT,
} from './QuizResultCommon';

export default (state = initialState, action = {}) => {
  const { type: actionType, payload: actionPayload } = action;
  console.log('>>action<<', actionType, actionPayload);
  switch (actionType) {

    // Set current student
    case SET_CURRENT_STUDENT:
      return (() => {
        return { ...state, currentStudent: actionPayload };
      })();

    // Save SDQ result
    case SAVE_SDQ_RESULT:
      return (() => {
        localStorage.sdqResult = JSON.stringify(actionPayload);
        return { ...state, sdqResult: actionPayload };
      })();

    // Save SNAP-IV result
    case SAVE_SNAP_IV_RESULT:
      return (() => {
        localStorage.snapIvResult = JSON.stringify(actionPayload);
        return { ...state, snapIvResult: actionPayload };
      })();

    // Save EQ result
    case SAVE_EQ_RESULT:
      return (() => {
        localStorage.eqResult = JSON.stringify(actionPayload);
        return { ...state, eqResult: actionPayload };
      })();

    // Save S9 result
    case SAVE_S9_RESULT:
      return (() => {
        localStorage.s9Result = JSON.stringify(actionPayload);
        return { ...state, s9Result: actionPayload };
      })();

    // Save WHO 5 result
    case SAVE_WHO5_RESULT:
      return (() => {
        localStorage.who5Result = JSON.stringify(actionPayload);
        return { ...state, who5Result: actionPayload };
      })();

    default:
      console.log('>>state<<', state);
      return state;

  }
}
