import {
  initialState,
  SET_CURRENT_USER,
  LOGIN, LOGIN_FULFILLED, LOGIN_REJECTED,
  LOGOUT, LOGOUT_FULFILLED,
  REGISTER, REGISTER_FULFILLED, REGISTER_REJECTED,
  UPDATE_USER, UPDATE_USER_FULFILLED, UPDATE_USER_REJECTED,
  LINE_LOGIN_FAILED, LINE_BINDING_FAILED,
} from './UserCommon';

export default (state = {...initialState}, action = {}) => {
  const { type: actionType, payload: actionPayload } = action;
  switch (actionType) {

    // Set user from session
    case SET_CURRENT_USER:
      return (() => {
        console.log('>>redux<<', actionType, actionPayload);
        return { ...state, currentUser: actionPayload };
      })();

    case LINE_LOGIN_FAILED:
      return (() => {
        return { ...state, lineLoginError: actionPayload };
      })();
    
    case LINE_BINDING_FAILED:
      return (() => {
        return { ...state, lineBindingError: actionPayload };
      })();

    // Login
    case LOGIN:
      return (() => {
        return { ...state, loginError: null };
      })();

    // Login, success
    case LOGIN_FULFILLED:
      return (() => {
        const currentUser = actionPayload;
        localStorage.currentUser = JSON.stringify(currentUser);
        return { ...state, loginError: null, currentUser };
      })();

    // Login, failed
    case LOGIN_REJECTED:
      return (() => {
        const { message } = actionPayload;
        console.log('>>login_error<<', message);
        return { ...state, loginError: actionPayload };
      })();

      // Logout
    case LOGOUT_FULFILLED:
      delete localStorage.currentUser;
      return (() => {
        return { ...initialState };
      })();

    // Register
    case REGISTER:
      return (() => {
        return { ...state, registerError: null };
      });

    // Register, success
    case UPDATE_USER_FULFILLED:
    case REGISTER_FULFILLED:
      return (() => {
        return { ...initialState, currentUser: null };
      })();

   // Register, failed
   //  case REGISTER_REJECTED:
   //    return (() => {
   //      return { ...state, currentUser: actionPayload };
   //    })();

    default:
      return state;
  }
}
