import {
  initialState,
  SET_CURRENT_STUDENT_RESULT,
  CREATE_CLASSROOM, CREATE_CLASSROOM_FULFILLED, CREATE_CLASSROOM_REJECTED,
  REMOVE_CLASSROOM, REMOVE_CLASSROOM_FULFILLED, REMOVE_CLASSROOM_REJECTED,
  ADD_STUDENT, ADD_STUDENT_FULFILLED, ADD_STUDENT_REJECTED,
  LIST_SEMESTERS, LIST_SEMESTERS_FULFILLED, LIST_SEMESTERS_REJECTED,
  CREATE_SEMESTER, CREATE_SEMESTER_FULFILLED, CREATE_SEMESTER_REJECTED,
  REMOVE_SEMESTER, REMOVE_SEMESTER_FULFILLED, REMOVE_SEMESTER_REJECTED,
  LIST_STUDENTS, LIST_STUDENTS_FULFILLED, LIST_STUDENTS_REJECTED,
  CREATE_STUDENT, CREATE_STUDENT_FULFILLED, CREATE_STUDENT_REJECTED,
  UPDATE_STUDENT, UPDATE_STUDENT_FULFILLED, UPDATE_STUDENT_REJECTED,
  REMOVE_STUDENT, REMOVE_STUDENT_FULFILLED, REMOVE_STUDENT_REJECTED,
  DELETE_STUDENT, DELETE_STUDENT_FULFILLED, DELETE_STUDENT_REJECTED,
} from './SchoolCommon';
import {setCurrentStudentResult} from "./SchoolAction";

export default (state = initialState, action = {}) => {
  const { type: actionType, payload: actionPayload } = action;
  console.log('>>action_redux<<', actionType, actionPayload);
  switch (actionType) {

    // Create classes, success
    case CREATE_CLASSROOM_FULFILLED:
    case REMOVE_CLASSROOM_FULFILLED:
      return (() => {
        const { userInstitute: { classrooms } } = actionPayload;
        console.log('>>class_rooms_ap<<', classrooms);
        return { ...state, classrooms };
      })();

    // Set current student
    case SET_CURRENT_STUDENT_RESULT:
      return (() => {
        console.log('>>current_student_result<<', actionPayload);
        localStorage.currentStudentResult = JSON.stringify(actionPayload);
        return { ...state, currentStudentResult: actionPayload };
      })();

    // Add student to semester
    case ADD_STUDENT_FULFILLED:
    case UPDATE_STUDENT_FULFILLED:
    case REMOVE_STUDENT_FULFILLED:
      return (() => {
        const { semesters } = state;
        const { id: semesterId } = actionPayload;
        const semester = semesters.find(s => s.id === semesterId);
        const index = semesters.indexOf(semester);
        if (index !== -1) {
          semesters[index] = actionPayload;
        }
        semesters.sort((a, b) => b.semester - a.semester);
        return { ...state, semesters: [...semesters] };
      })();

    // List / create / remove semesters, success
    case LIST_SEMESTERS_FULFILLED:
    case CREATE_SEMESTER_FULFILLED:
    case REMOVE_SEMESTER_FULFILLED:
      return (() => {
        const semesters = actionPayload.sort((a, b) => {
          console.log('>>compare<<', b.semester, a.semester);
          return b.semester - a.semester;
        });
        return { ...state, semesters };
      })();

    // List/Create students, success
    case LIST_STUDENTS_FULFILLED:
    case CREATE_STUDENT_FULFILLED:
    case DELETE_STUDENT_FULFILLED:
      return (() => {
        return { ...state, students: actionPayload };
      })();

    default:
      return state;

  }
}
