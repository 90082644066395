import React from 'react';
import { asyncComponent } from '@jaredpalmer/after';

export default [
  // {
  //   path: '/',
  //   exact: false,
  //   component: asyncComponent({
  //     loader: () => import('./react/pages/Sorry'), // required
  //     Placeholder: () => null, // this is optional, just returns null by default
  //   }),
  // },
  {
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/Login'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/edit',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/UserEdit'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/register',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/Registration'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/register-line/:userLineId/:userEmail',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/RegistrationLine'), // this is optional, just returns null by default
      Placeholder: () => null,
    })
  },
  {
    path: '/register-consultant',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/Registration'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/assignClassrooms',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AssignClassrooms'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/advisor/classrooms',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AdvisorClassrooms'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/advisor/classroom/:classroomId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AdvisorSemester'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/advisor/classroom/:classroomId/semester/:semesterId/:tableMode',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AdvisorSemester'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/admin/classroom/:classroomId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AdminSemester'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/admin/classroom/:classroomId/semester/:semesterId/:tableMode',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AdminSemester'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/self/quiz/:quizType',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/SelfQuiz'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/semester-student/:semesterStudentId/quiz/:quizId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/StudentQuiz'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/student-quiz/:studentQuizId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/StudentQuizResult'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/student-history/s/:studentId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/StudentHistory'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/sdq/quiz',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/SdqQuiz'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/managementPage',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AdvisorManagementPage'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/AdvisorManagementPage/:instituteId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AdvisorManagementPage'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  // {
  //   path: '/consultantManagement/:medicalCentreId',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('./react/pages/ConsultantManagement'), // required
  //     Placeholder: () => null, // this is optional, just returns null by default
  //   }),
  // },
  {
    path: '/consultantManagement',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/ConsultantManagement'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/addConsultant',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AddCounsultantNew'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  // {
  //   path: '/addConsultant/:medicalCentreId',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('./react/pages/AddConsultant'), // required
  //     Placeholder: () => null, // this is optional, just returns null by default
  //   }),
  // },
  {
    path: '/editConsultant/:userId/medicalCentre/:medicalCentreId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/EditConsultant'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/adminManagementPage',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AdminManagementPage'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/instituteManagementPage',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/InstitutionManagement'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/medicalCentreManagementPage',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/MedicalCentreManagement'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/mentalHealthCentreManagementPage',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/MentalHealthCentreManagement'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/addMentalHealthCentreStaff',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AddMentalHealthCentreStaff'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/editMentalHealthCentreStaff/:userId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/EditMentalHealthCentreStaff'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/classroom_management',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/ClassroomManagement'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/classroom_management/:instituteId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/ClassroomManagement'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/studentManagementPage/:instituteId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/StudentManagementPage'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/editUser/:userID/institute/:instituteID',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/EditUser'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/addUser/:instituteId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AddUser'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/addUser/:instituteId/:displayedRole',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AddUser'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/addAdmin',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AddAdmin'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/editAdmin/:userID',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/EditAdmin'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/edit-master-data',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/EditMasterData'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    path: '/sdq/result',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/SdqResult'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/who5/quiz',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/Who5Quiz'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/who5/result',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/Who5Result'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/quiz/thankyou',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/QuizThankYou'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/quiz/duplicate',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/QuizDuplicate'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/dashboard',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/Dashboard'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/consultant/consultations',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/Consultations'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/advisor/consultations',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/Consultations'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/consultation/:id/chat',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/Chat'),
      Placeholder: () => null,
    })
  },
  {
    path: '/password',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/ResetPassword'),
      Placeholder: () => null,
    })
  },
  {
    path: '/activate',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/Activation'),
      Placeholder: () => null,
    })
  },
  {
    path: '/report',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/Report'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/report/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/Report'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/reports/studentAssessmentResults',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/StudentAssessmentResults'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/reports/studentAssessmentResults/:role/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/StudentAssessmentResults'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/reports/consultingStatistics',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/ConsultingStatistics'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/reports/consultingStatistics/:role/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/ConsultingStatistics'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/reports/accessStatistics',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AccessStatistics'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/reports/accessStatistics/:role/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/AccessStatistics'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/reports/EvaluationsStatistics',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/EvaluationsStatistics'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/reportManagement',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/ReportManagement'),
      Placeholder: () => null,
    }),
  },
  {
    path: '/admin/classroom-assignment',
    exact: true,
    component: asyncComponent({
      loader: () => import('./react/pages/ClassroomAssignment'), // required
      Placeholder: () => null, // this is optional, just returns null by default
    }),
  },
  {
    component: asyncComponent({
      loader: () => import('./react/pages/NotFound'),
      Placeholder: () => null,
    })
  },
];
