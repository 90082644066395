import axios from 'axios';

import {extract} from '../../utils/ApiDataExtractor';
import {sortByThaiTextProp} from '../../utils/TextUtils';

class HttpClassroomRepo {

  static sortClassrooms(classrooms) {
    if (!classrooms || !classrooms.length) {
      return;
    }
    console.log('>>classrooms<<', classrooms);

    const [sampleClassroom] = classrooms;
    if (sampleClassroom.academicYear) {
      const yearClassroomMap = new Map();
      for (const classroom of classrooms) {
        const {academicYear} = classroom;
        yearClassroomMap.set(academicYear, [...(yearClassroomMap.get(academicYear) || []), classroom]);
      }
      for (const classrooms of yearClassroomMap.values()) {
        console.log('>>classrooms_000<<', classrooms);
        sortByThaiTextProp(classrooms, [
          'classroomGrade.academicLevel.name.th', 'classroomGrade.gradeNumber', 'roomNumber'
        ]);
      }
      const sortedClassrooms = [];
      const years = [...yearClassroomMap.keys()];
      years.sort((a, b) => b - a);

      for (const year of years) {
        const classrooms = yearClassroomMap.get(year);
        for (const classroom of classrooms) {
          sortedClassrooms.push(classroom);
        }
      }
      return sortedClassrooms;
    } else {
      return sortByThaiTextProp(classrooms, [
        'classroomGrade.academicLevel.name.th', 'classroomGrade.gradeNumber', 'roomNumber'
      ]);
    }
  }

  static async findById(id) {
    return extract(await axios.get(`/api/classroom/${id}`));
  }

  static async list() {
    return HttpClassroomRepo.sortClassrooms(await extract(await axios.get('/api/classroom')));
  }

  static async listByInstituteId(instituteId) {
    return extract(await axios.get(`/api/classroom/i/${instituteId}`));
  }

  static async listByInstituteIdHiddenSoftDelete(instituteId) {
    return extract(await axios.get(`/api/classroom/i/${instituteId}/hsd`));
  }

  static async listByInstituteIdAndYear(instituteId, year) {
    return extract(await axios.get(`/api/classroom/list/i/${instituteId}/y/${year}`));
  }

  static async listByInstituteIdYearAndPage(instituteId, year, page=1) {
    return extract(await axios.get(`/api/classroom/i/${instituteId}/y/${year}?page=${page}`));
  }

  static async listByAdvisorId(advisorId) {
    return extract(await axios.get(`/api/classroom/a/${advisorId}`));
  }

  static async create(input = {}) {
    return extract(await axios.post('/api/classroom', input));
  }

  static async clone(id, input) {
    return extract(await axios.post(`/api/classroom/${id}/clone`, input));
  }

  static async createByAdmin(input = {}) {
    return extract(await axios.post('/api/classroom/createByAdmin', input));
  }

  static async assignClassrooms(input = {}) {
    return extract(await axios.post('/api/classroom/assignClassrooms', input));
  }

  static async update(id, input = {}) {
    return extract(await axios.patch(`/api/classroom/${id}`, input));
  }

  static async remove(id) {
    return extract(await axios.delete(`/api/classroom/${id}`));
  }

  static async setNote(id, input={}) {
    return extract(await axios.put(`/api/classroom/note/${id}`, input))
  }

  static async softDelete(id, input={}) {
    return extract(await axios.post(`/api/classroom/sd/${id}`, input));
  }

  static async restore(id, input={}) {
    return extract(await axios.post(`/api/classroom/restore/${id}`, input));
  }

}

export default HttpClassroomRepo;
