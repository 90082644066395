import { combineReducers } from 'redux';

import MasterDataReducer from './master-data/MasterDataReducer';
import UserReducer from './user/UserReducer';
import SchoolReducer from './school/SchoolReducer';
import QuizResultReducer from './quiz-result/QuizResultReducer';

export default combineReducers({
  masterData: MasterDataReducer,
  user: UserReducer,
  school: SchoolReducer,
  quizResult: QuizResultReducer,
});
