import {cloneDeep, get as getPropValue} from 'lodash';
import moment from 'moment';

const thaiFrontVowels = ['เ', 'แ', 'ไ', 'ใ', 'โ'];

export const capitalise = text => {
  if (!text || text.length === 0) {
    return '';
  }
  if (text.length === 1) {
    return text.toUpperCase();
  }
  const char0 = text.charAt(0);
  const theRest = text.substring(1);
  return `${char0}${theRest}`;
};

const swapItems = (array, i, j) => {
  const temp = array[i];
  array[i] = array[j];
  array[j] = temp;
};

const reorderThaiCharacters = text => {
  if (!text) {
    return '';
  }
  const characters = text.split('');
  for (let index = characters.length - 2; index >= 0; index--) {
    const character = characters[index];
    if (thaiFrontVowels.indexOf(character) === -1) {
      continue;
    }
    swapItems(characters, index, index + 1);
  }
  return characters.join('');
}

export const sortByThaiTextProp = (originalArray = [], propNames = [], asc = true) => {
  const array = cloneDeep(originalArray);
  let index = 0;
  for (const item of array) {
    const valueToSort = propNames.map(propName => reorderThaiCharacters(getPropValue(item, propName) + '')).join('_')
    item.__sortKey = reorderThaiCharacters(`${valueToSort}_${index}`);
    index++;
  }
  array.sort((a, b) => {
    if (a.__sortKey > b.__sortKey) {
      return asc ? 1 : -1;
    } else if (a.__sortKey < b.__sortKey) {
      return asc ? -1 : 1;
    }
    return 0;
  });

  for (const item of array) {
    delete item.__sortKey;
  }
  return array;
}

export const sortByNewToOld = (a, b) => {
  if (a < b) return 1
  else if (a > b) return -1
  else return 0
}

export const sortByOrder = (a, b) => {
  if (a.order > b.order) return 1
  else if (a.order < b.order) return -1
  else return 0
}

export const sortByAcademicYearNewToOld = (a, b) => {
  if (a.academicYear < b.academicYear) return 1
  else if (a.academicYear > b.academicYear) return -1
  else return 0
}

export const sortByTimeOldToNew = (a, b) => {
  if (moment(a.createdAt).isAfter(moment(b.createdAt))) return 1
  else if (moment(b.createdAt).isAfter(moment(a.createdAt))) return -1
  else return 0
}
