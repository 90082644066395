import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import reducers  from "./reducers";

const configureStore = preloadState => {
  const store = createStore(
    reducers,
    preloadState,
    applyMiddleware(promiseMiddleware),
  );

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
