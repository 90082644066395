export const getUser = () => {
  let user;
  try {
    user = JSON.parse(localStorage.currentUser);
  } catch (error) {
    console.log('>>parse_student_error<<', error);
  }
  if (!user) {
    return Promise.reject(new Error('User not found'));
  }
  return user;
};

export const fetchUsers = () => {
  let users;
  try {
    users = JSON.parse(localStorage.users);
  } catch (error) {
    console.log('>>parse_users_error<<', error);
  }

  if (!users) {
    users = [];
    localStorage.users = JSON.stringify(users);
  }
  return users;
};

export const storeUser = user => {
  localStorage.currentUser = JSON.stringify(user);

  const users = fetchUsers();
  const storedUser = users.find(u => u.id === user.id);
  if (!storedUser) {
    return;
  }

  const index = users.indexOf(storedUser);
  users[index] = user;
  localStorage.users = JSON.stringify([...users]);
};
