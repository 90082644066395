import {
  initialState,
  FETCH_SERVER_DATA, FETCH_SERVER_DATA_FULFILLED, FETCH_SERVER_DATA_REJECTED,
  FETCH_SDQ_QUIZ, FETCH_SDQ_QUIZ_FULFILLED, FETCH_SDQ_QUIZ_REJECTED,
  FETCH_SNAP_IV_QUIZ, FETCH_SNAP_IV_QUIZ_FULFILLED, FETCH_SNAP_IV_QUIZ_REJECTED,
  FETCH_EQ_QUIZ, FETCH_EQ_QUIZ_FULFILLED, FETCH_EQ_QUIZ_REJECTED,
  FETCH_S9_QUIZ, FETCH_S9_QUIZ_FULFILLED, FETCH_S9_QUIZ_REJECTED,
  FETCH_WHO5_QUIZ, FETCH_WHO5_QUIZ_FULFILLED, FETCH_WHO5_QUIZ_REJECTED,
} from './MasterDataCommon';

export default (state = initialState, action) => {
  const { type: actionType, payload: actionPayload } = action;
  switch (actionType) {

    // Fetch Server Data, Success
    case FETCH_SERVER_DATA_FULFILLED:
      return (() => {
        const masterData = { ...actionPayload };
        let { levels } = masterData;
        if (process.env.RAZZLE_APP_MODE === 'voc') {
          masterData.levels = levels.slice(2);
        } else {
          masterData.levels  = levels.slice(0, 2);
        }
        return { ...state, ...masterData, serverDataFetched: true };
      })();

    // Fetch SDQ Quiz, Success
    case FETCH_SDQ_QUIZ_FULFILLED:
      return (() => {
        return { ...state, sdqQuiz: actionPayload };
      })();

    // Fetch SNAP/IV Quiz, Success
    case FETCH_SNAP_IV_QUIZ_FULFILLED:
      return (() => {
        console.log('>>snap_iv<<', actionPayload);
        return { ...state, snapIvQuiz: actionPayload };
      })();

    // Fetch EQ Quiz, Success
    case FETCH_EQ_QUIZ_FULFILLED:
      return (() => {
        return { ...state, eqQuiz: actionPayload };
      })();

    // Fetch S9, Quiz, Success
    case FETCH_S9_QUIZ_FULFILLED:
      return (() => {
        return { ...state, s9Quiz: actionPayload };
      })();

    // Fetch WHO5, Quiz, Success
    case FETCH_WHO5_QUIZ_FULFILLED:
      return (() => {
        return { ...state, who5Quiz: actionPayload };
      })();

    default:
      return state;
  }
}
