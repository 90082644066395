import MockStudentRepo from '../../repos/StudentRepo';
import ClassroomRepo from '../../repos/ClassroomRepo';
import SemesterRepo from '../../repos/SemesterRepo';
import {
  CREATE_CLASSROOM, REMOVE_CLASSROOM,
  CREATE_SEMESTER, LIST_SEMESTERS, REMOVE_SEMESTER,
  ADD_STUDENT, UPDATE_STUDENT, REMOVE_STUDENT, SET_CURRENT_STUDENT_RESULT,
  LIST_STUDENTS, CREATE_STUDENT, DELETE_STUDENT,
} from './SchoolCommon';

export const createClassroom = input => {
  return { type: CREATE_CLASSROOM, payload: ClassroomRepo.create(input) };
};

export const removeClassroom = id => {
  return { type: REMOVE_CLASSROOM, payload: ClassroomRepo.remove(id) };
};

export const listSemesters = classroomId => {
  return { type: LIST_SEMESTERS, payload: SemesterRepo.list(classroomId) };
};

export const createSemester = (classroomId, input) => {
  return { type: CREATE_SEMESTER, payload: SemesterRepo.create(classroomId, input) };
};

export const removeSemester = (classroomId, semesterId) => {
  return { type: REMOVE_SEMESTER, payload: SemesterRepo.remove(classroomId, semesterId) };
};

export const setCurrentStudentResult = student => {
  return { type: SET_CURRENT_STUDENT_RESULT, payload: student };
};

export const addStudent = (semesterId, input) => {
  return { type: ADD_STUDENT, payload: SemesterRepo.addStudent(semesterId, input) };
};

export const updateStudent = (semesterId, studentId, studentInput) => {
  return { type: UPDATE_STUDENT, payload: SemesterRepo.updateStudent(semesterId, studentId, studentInput) };
};

export const updateStudentBySdqToken = (token, studentInput) => {
  return { type: UPDATE_STUDENT, payload: SemesterRepo.updateStudentBySdqAccessToken(token, studentInput) };
};

export const updateStudentByWho5Token = (token, studentInput) => {
  return { type: UPDATE_STUDENT, payload: SemesterRepo.updateStudentByWho5AccessToken(token, studentInput) };
};

export const removeStudent = (semesterId, studentId) => {
  return { type: REMOVE_STUDENT, payload: SemesterRepo.removeStudent(semesterId, studentId) };
};

export const listStudents = (year, grade, room) => {
  return { type: LIST_STUDENTS, payload: MockStudentRepo.list(year, grade, room) };
};

export const createStudent = studentInput => {
  return { type: CREATE_STUDENT, payload: MockStudentRepo.create(studentInput) };
};

export const deleteStudent = id => {
  return { type: DELETE_STUDENT, payload: MockStudentRepo.delete(id) };
};
