export const initialState = {
  serverDataFetched: false,
  sdqQuiz: null,
  snapIvQuiz: null,
  eqQuiz: null,
  s9Quiz: null,
  who5Quiz: null,
};

export const FETCH_SERVER_DATA = 'FETCH_SERVER_DATA';
export const FETCH_SERVER_DATA_FULFILLED = 'FETCH_SERVER_DATA_FULFILLED';
export const FETCH_SERVER_DATA_REJECTED = 'FETCH_SERVER_DATA_REJECTED';

export const FETCH_SDQ_QUIZ = 'FETCH_SDQ_QUIZ';
export const FETCH_SDQ_QUIZ_FULFILLED = 'FETCH_SDQ_QUIZ_FULFILLED';
export const FETCH_SDQ_QUIZ_REJECTED = 'FETCH_SDQ_QUIZ_REJECTED';

export const FETCH_SNAP_IV_QUIZ = 'FETCH_SNAP_IV_QUIZ';
export const FETCH_SNAP_IV_QUIZ_FULFILLED = 'FETCH_SNAP_IV_QUIZ_FULFILLED';
export const FETCH_SNAP_IV_QUIZ_REJECTED = 'FETCH_SNAP_IV_QUIZ_REJECTED';

export const FETCH_EQ_QUIZ = 'FETCH_EQ_QUIZ';
export const FETCH_EQ_QUIZ_FULFILLED = 'FETCH_EQ_QUIZ_FULFILLED';
export const FETCH_EQ_QUIZ_REJECTED = 'FETCH_EQ_QUIZ_REJECTED';

export const FETCH_S9_QUIZ = 'FETCH_S9_QUIZ';
export const FETCH_S9_QUIZ_FULFILLED = 'FETCH_S9_QUIZ_FULFILLED';
export const FETCH_S9_QUIZ_REJECTED = 'FETCH_S9_QUIZ_REJECTED';

export const FETCH_WHO5_QUIZ = 'FETCH_WHO5_QUIZ';
export const FETCH_WHO5_QUIZ_FULFILLED = 'FETCH_WHO5_QUIZ_FULFILLED';
export const FETCH_WHO5_QUIZ_REJECTED = 'FETCH_WHO5_QUIZ_REJECTED';
