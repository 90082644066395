let storedSdqResult;
if (typeof localStorage !== 'undefined' && localStorage.sdqResult) {
  try {
    storedSdqResult = JSON.parse(localStorage.sdqResult);
  } catch (error) {
    console.log('>>parse_sdq_result_error<<', error);
  }
}

let storedSnapIvResult;
if (typeof localStorage !== 'undefined' && localStorage.snapIvResult) {
  try {
    storedSnapIvResult = JSON.parse(localStorage.snapIvResult);
  } catch (error) {
    console.log('>>parse_snap_iv_result_error<<', error);
  }
}

let storedEqResult;
if (typeof localStorage !== 'undefined' && localStorage.eqResult) {
  try {
    storedEqResult = JSON.parse(localStorage.eqResult);
  } catch (error) {
    console.log('>>parse_eq_result_error<<', error);
  }
}

let storedS9Result;
if (typeof localStorage !== 'undefined' && localStorage.s9Result) {
  try {
    storedS9Result = JSON.parse(localStorage.s9Result);
  } catch (error) {
    console.log('>>parse_sdq_result_error<<', error);
  }
}

let storedWho5Result;
if (typeof localStorage !== 'undefined' && localStorage.who5Result) {
  try {
    storedWho5Result = JSON.parse(localStorage.who5Result);
    console.log('>>stored_who5_result<<', storedWho5Result);
  } catch (error) {
    console.log('>>parse_who5_result_error<<', error);
  }
}

export const initialState = {
  currentStudent: null,
  sdqResult: storedSdqResult || {},
  snapIvResult: storedSnapIvResult || {},
  eqResult: storedEqResult || {},
  s9Result: storedS9Result || {},
  who5Result: storedWho5Result || {},
};

export const SET_CURRENT_STUDENT = 'SET_CURRENT_STUDENT';
export const SAVE_SDQ_RESULT = 'SAVE_SDQ_RESULT';
export const SAVE_SNAP_IV_RESULT = 'SAVE_SNAP_IV_RESULT';
export const SAVE_EQ_RESULT = 'SAVE_EQ_RESULT';
export const SAVE_S9_RESULT = 'SAVE_S9_RESULT';
export const SAVE_WHO5_RESULT = 'SAVE_WHO5_RESULT';
