let currentStudentResult;
try {
  currentStudentResult = JSON.parse(localStorage.currentStudentResult);
} catch (error) {
  currentStudentResult = null;
}
export const initialState = {
  educationYears: [],
  classrooms: [],
  semesters: [],
  students: [],
  currentStudentResult,
};

export const CREATE_CLASSROOM = 'CREATE_CLASSROOM';
export const CREATE_CLASSROOM_FULFILLED = 'CREATE_CLASSROOM_FULFILLED';
export const CREATE_CLASSROOM_REJECTED = 'CREATE_CLASSROOM_REJECTED';

export const REMOVE_CLASSROOM = 'REMOVE_CLASSROOM';
export const REMOVE_CLASSROOM_FULFILLED = 'REMOVE_CLASSROOM_FULFILLED';
export const REMOVE_CLASSROOM_REJECTED = 'REMOVE_CLASSROOM_REJECTED';

export const LIST_SEMESTERS = 'LIST_SEMESTERS';
export const LIST_SEMESTERS_FULFILLED = 'LIST_SEMESTERS_FULFILLED';
export const LIST_SEMESTERS_REJECTED = 'LIST_SEMESTERS_REJECTED';

export const CREATE_SEMESTER = 'CREATE_SEMESTER';
export const CREATE_SEMESTER_FULFILLED = 'CREATE_SEMESTER_FULFILLED';
export const CREATE_SEMESTER_REJECTED = 'CREATE_SEMESTER_REJECTED';

export const REMOVE_SEMESTER = 'REMOVE_SEMESTER';
export const REMOVE_SEMESTER_FULFILLED = 'REMOVE_SEMESTER_FULFILLED';
export const REMOVE_SEMESTER_REJECTED = 'REMOVE_SEMESTER_REJECTED';

export const SET_CURRENT_STUDENT_RESULT = 'SET_CURRENT_STUDENT_RESULT';

export const ADD_STUDENT = 'ADD_STUDENT';
export const ADD_STUDENT_FULFILLED = 'ADD_STUDENT_FULFILLED';
export const ADD_STUDENT_REJECTED = 'ADD_STUDENT_REJECTED';

export const LIST_STUDENTS = 'LIST_STUDENTS';
export const LIST_STUDENTS_FULFILLED = 'LIST_STUDENTS_FULFILLED';
export const LIST_STUDENTS_REJECTED = 'LIST_STUDENTS_REJECTED';

export const CREATE_STUDENT = 'CREATE_STUDENT';
export const CREATE_STUDENT_FULFILLED = 'CREATE_STUDENT_FULFILLED';
export const CREATE_STUDENT_REJECTED = 'CREATE_STUDENT_REJECTED';

export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const UPDATE_STUDENT_FULFILLED = 'UPDATE_STUDENT_FULFILLED';
export const UPDATE_STUDENT_REJECTED = 'UPDATE_STUDENT_REJECTED';

export const REMOVE_STUDENT = 'REMOVE_STUDENT';
export const REMOVE_STUDENT_FULFILLED = 'REMOVE_STUDENT_FULFILLED';
export const REMOVE_STUDENT_REJECTED = 'REMOVE_STUDENT_REJECTED';

export const DELETE_STUDENT = 'DELETE_STUDENT';
export const DELETE_STUDENT_FULFILLED = 'DELETE_STUDENT_FULFILLED';
export const DELETE_STUDENT_REJECTED = 'DELETE_STUDENT_REJECTED';
