import axios from 'axios';

import { extract } from '../../utils/ApiDataExtractor';

class HttpStudentRepo {

  static async findById(id) {
    return extract(await axios.get(`/api/student/${id}`));
  }
  static async findByCitizenNumber(citizenNumber) {
    return extract(await axios.get(`/api/student/cn/${citizenNumber}`));
  }

  static async create(input = {}) {
    return extract(await axios.post(`/api/student`, input));
  }

  static async update(id, input = {}) {
    return extract(await axios.patch(`/api/student/${id}`, input));
  }

  static async getStudent(instituteId, year, classroomId, page) {
    return extract(await axios.get(`/api/student/getStudent/i/${instituteId}/y/${year}/c/${classroomId}?page=${page}`));
  }

}

export default HttpStudentRepo;
