import axios from 'axios';

import {extract} from '../../utils/ApiDataExtractor';
import {sortByThaiTextProp} from '../../utils/TextUtils';

class HttpSemesterRepo {

  static sortStudentsByName(semester) {
    semester.semesterStudents = sortByThaiTextProp(
      semester.semesterStudents || [],
      ['student.firstName', 'student.lastName']
    );
    return semester;
  }

  static async findById(id) {
    const semester = await extract(await axios.get(`/api/semester/${id}`));
    console.log('>>semester_repo_semester<<', semester);
    return HttpSemesterRepo.sortStudentsByName(semester);
  }

  static async findByIdOnly(id) {
    const semester = await extract(await axios.get(`/api/semester/idOnly/${id}`));
    console.log('>>semester_repo_semester<<', semester);
    return HttpSemesterRepo.sortStudentsByName(semester);
  }

  static async list(classroomId) {
    return extract(await axios.get(`/api/semester/c/${classroomId}`));
  }

  static async findByClassroomYearAndSemester(classroomId, year, semesterNumber) {
    const semester = await extract(await axios.get(`/api/semester/c/${classroomId}/y/${year}/s/${semesterNumber}`));
    return HttpSemesterRepo.sortStudentsByName(semester);
  }

  static async create(input = {}) {
    return extract(await axios.post(`/api/semester`, input));
  }

  static async remove(id) {
    return extract(await axios.delete(`/api/semester/${id}`));
  }

}

export default HttpSemesterRepo;
