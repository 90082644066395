import { getUser, storeUser } from '../Common/MockUtil';

class MockStudentRepo {

  static sort(students) {
    return students.sort((a, b) => a.studentNumber - b.studentNumber);
  }

  static filter(students, year, grade, room) {
    return students.filter(s => s.year === year && s.grade === grade && s.room === room);
  }

  static async list(year, grade, room) {
    const user = await getUser();
    if (!user.students) {
      user.students = [];
      localStorage.currentUser = JSON.stringify(user);
    }
    const { students } = user;
    if (!year && !grade && !room) {
      return this.sort(students);
    }
    return this.sort(this.filter(students, year, grade, room));
  }

  static async create(studentInput) {
    const user = await getUser();
    const students = await this.list();
    const { year, grade, room, studentNumber, idNumber } = studentInput;
    if (
      this.filter(students, year, grade, room)
        .find(s => s.studentNumber === studentNumber || s.idNumber === idNumber)
    ) {
      return Promise.reject('Duplicate');
    }
    students.push({ id: Date.now(), ...studentInput });
    console.log('>>students<<', students);
    user.students = [...students];
    storeUser(user);
    return this.sort(this.filter(students, year, grade, room));
  }

  static async update(id, studentInput) {
    const user = await getUser();
    const students = await this.list();
    const { year, grade, room } = studentInput;
    let student;
    if (!(student = this.filter(students, year, grade, room).find(s => s.id === id))) {
      return Promise.reject('Student not found');
    }
    const index = students.indexOf(student);
    students[index] = studentInput;
    console.log('>>students<<', students);
    user.students = [...students];
    storeUser(user);
    return this.sort(this.filter(students, year, grade, room));
  }

  static async delete(id) {
    const user = await getUser();
    const students = await this.list();
    let student;
    if (!(student = students.find(s => s.id === id))) {
      return Promise.reject('Student not found');
    }
    const index = students.indexOf(student);
    students.splice(index, 1);
    console.log('>>students<<', students);
    user.students = [...students];
    storeUser(user);
    const { year, grade, room } = student;
    return this.sort(this.filter(students, year, grade, room));
  }

}

export default MockStudentRepo;
