export const initialState = {
  currentUser: null,
  loginError: null,
  registerError: null,
  lineLoginError: null,
  lineBindingError: null,
};

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LINE_BINDING_FAILED = 'LINE_BINDING_FAILED';

export const REGISTER = 'REGISTER';
export const REGISTER_FULFILLED = 'REGISTER_FULFILLED';
export const REGISTER_REJECTED = 'REGISTER_REJECTED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED';
export const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED';

export const LOGIN = 'LOGIN';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';
export const LINE_LOGIN_FAILED = 'LINE_LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED';
